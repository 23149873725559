import React from 'react'
import shapes from '../../../assets/projects/sorting/files2.PNG';
import result from '../../../assets/projects/sorting/result.PNG';

const AlgoSort = () => {
  return (
    <div>
        <div className='viewscontent-block'>
            <div className='text-container'>
                <h2>The Goal</h2><br/>
                <p>Ever need to sort a giant file of shape types and their sizes? No? Well I have a solution if you ever do!<br/>
                    The ultimate goal for this project was to take a file of shapes, sort them based on area, height or volume, and perform this all with the command line.<br/>
                    <br/>There are 6 sorting algorithms used: <br/><br/>
                    <div className='points-container'>
                        <ul className='points' style={{textAlign:'center'}}>
                            <li><b>Bubble</b></li>
                            <li><b>Selection</b></li>
                            <li><b>Insertion</b></li>
                            <li><b>Merge</b></li>
                            <li><b>Quick</b></li>
                            <li><b>Shell</b></li>
                        </ul>
                    </div>
                </p>
            </div>
        </div>
        <hr/>
        <div className='viewscontent-block'>
            <div className='img-container'>
                <img src={shapes} alt="projectPicture" draggable="false"/>
            </div>
            <div className='text-container'>
                <h2>The Plan</h2> <br/>
                <p> I created an abstract comparable 'polygon' class that could be extended in other shape classes such as Cone or Cylinder. 
                    This allowed me to then use the <span className='tech-words'>Class.forName</span> method to utilize the class with the correct shape and load these all into an array.
                    <br/><br/>
                    After all shapes in the file have been read, the sorting begins!<br/>
                    Depending on the chosen sort, the array gets passed to the appropriate method where a compareTo method is utilized. 
                    This method is created as part of the Polygon class and relies on the selected comparison type (area, volume or height) to determine the math required to sort the shapes. 
                </p>
            </div>
           
        </div>
        <hr/>
        <div className='viewscontent-block'>
            <div className='text-container'>
                <h2>The Result</h2><br/>
                <p>Using the application via the command line successfully sorted the files and shapes within them. <br/><br/>The input used for the output seen here was:<br/> <span className='tech-words'>java -jar SortMe.jar -fpolyfor1.txt -Tv -Si</span><br/><br/>
                    There are multiple command line options that can be used, if interested please check out my project on <a href="https://github.com/caraMcG/SortingAlgorithms" className='projectViews-link' target='_blank'>GitHub</a> for a full read me!
                </p>
            </div>
            <div className='img-container'>
                <img src={result} alt="projectPicture" draggable="false"/>
            </div>
        </div>
    </div>
  )
}

export default AlgoSort