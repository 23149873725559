import React from 'react'
import { CodeBlock, dracula } from 'react-code-blocks';
import excelsqldata from '../../../assets/projects/pyscraper/exceldata_sqldata.png';

const PyScraper = () => {
  return (
    <div>
        <div className='viewscontent-block'>
            <div className='text-container'>
                <h2>The Goal</h2><br/>
                <p>This project was inspired by my reckless cat Ozzy, he, like many other cats loves to eat any plant that comes into the house.
                    The goal was to create a small <span className='tech-words'>Microsoft SQL Server</span> database populated with data on plants toxic to cats.<br/>
                    I quickly found that manual entry into a database was quite time consuming, so I challenged myself to learn some Python and create a webscraper for an efficient and speedy solution.<br/><br/>
                    Data entry time saved? <span className='check-words'>Check!</span> Python language learned? <span className='check-words'>Double Check!</span><br/><br/>
                    This program utilizes <span className='tech-words'>BeautifulSoup4</span>, <span className='tech-words'>requests</span> and <span className='tech-words'>pandas</span> libraries.<br/>
                    All data credit goes to <a href="https://www.rover.com/blog/poisonous-plants/" className='projectViews-link' target= "_blank">Rover</a>.<br/>
                            
                </p>
            </div>
            <div className='code-container'>
                < CodeBlock
                    text = {
                `from bs4 import BeautifulSoup
import requests
import pandas as pd

html = requests.get(
    'https://www.rover.com/blog/poisonous-plants/'
    ).text

soup = BeautifulSoup(html, 'lxml')`}
                    theme = {dracula}
                    codeBlock
                    wrapLines
                    showLineNumbers = {false}
                    language= "Python"
                />
            </div>
        </div>
        <hr/>
        <div className='viewscontent-block'>
            <div className='text-container'>
                <h2>The Plan</h2> <br/>
                <p>The <span className='tech-words'>.find_all</span> method is first used to load in the blocks of data I was interested in. These blocks include variables such as the plants name, it's scientific name, if it's typically
                    a household plant and if it is toxic to dogs and/or cats.<br/><br/>
                    Using a for loop the program iterates through the plant blocks and stores the data into a temporary variable that is then appended to a list.
                    Once the for loop has completed iterating through all plants the data is then stored into an excel document using a <span className='tech-words'>pandas dataframe</span>.
                </p>
            </div>
            <div className='code-container' id="pycode">
                < CodeBlock
                    text = {
`plants = soup.find_all('tr', class_='tile tile-plant')
counter = 0

for plant in plants:
    plant_name = plant.find('h4', class_='tile-title').text
    plant_science = plant.find('div', class_='scientific-name').text
    //etc...
    
df = pd.DataFrame({'Plant': names, 'Scientific': science_names,
                    'Cats': cats,'Toxicity Level': level})
df.to_csv('plantInfo_CSV.csv', index=False, encoding='utf-8-sig')`}
                    theme = {dracula}
                    codeBlock
                    wrapLines
                    showLineNumbers = {false}
                    language= "Python"
                />
            </div>
        </div>
        <hr/>
        <div className='viewscontent-block'>
            <div className='text-container'>
                <h2>The Result</h2><br/>
                <p>The program successfully exported the data to an excel file.<br/> The file was then imported to populate a <span className='tech-words'>Microsoft SQL Server</span> database table
                    for a web app project (shown in another project!).<br/><br/>
                    Future plans for this project include using the <span className='tech-words'>pyodbc</span> module and coding a function to automatically import and create a table in a local database. 
                </p>
            </div>
            <div className='img-container'>
                <img src={excelsqldata} alt="projectPicture" draggable="false"/>
            </div>
        </div>
    </div>
  )
}

export default PyScraper