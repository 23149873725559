import React from 'react';
// import { MetaTags } from 'react-meta-tags'

// import { Home, Navbar, About, Projects, Skills, Contact, Footer } from './components';
import { Home, Navbar, About, ProjectSlide, Skills, Contact, Footer } from './components';

import './App.css';

const App = () => {
  return (
    <div className="App">
      <div className = "pattern">
        <div className="gradient__bg">
          <Navbar/>
          <Home/>
        </div>
      </div>
      <About/>
      {/* <div className="projects_angled__bg"> */}
        {/* <Projects/> */}
      <ProjectSlide />
      {/* </div> */}
      <div className = "pattern">
        <div className="skills_angled__bg">
          <Skills/>
        </div>
      </div>
      <Contact/>
      <div className="footer__bg">
        <Footer/>
      </div>
    </div>
  )
}

export default App
