import React, { useState } from 'react';
import './home.css';
import me from '../../assets/me2.png';
import cats2 from '../../assets/cats2.png';
import cats3 from '../../assets/catsHover2.png';
import { TypeAnimation } from 'react-type-animation';

const Home = () => {
  const [imageChange, setImageChange] = useState(false);

  return (
    <div className = "portfolio__header section__margin">
        <div className="portfolio__header-content">
            <div className="portfolio__header-content-inner">
              <h1>Welcome</h1>
              <p>I'm <span style={{ color: "#002f3d", fontWeight: "800" }}>Cara</span>, a dev fueled by <span>
                <TypeAnimation
                    sequence={[
                      'Coffee & Tea',
                      2000,
                      'LoFi Music',    
                      2000,
                      'Cats',
                      2000,
                      'Missing Semicolons',
                      2000,
                      'Pure Caffeine',
                      2000,
                      'Dogs',   
                      2000,
                      'My Love of Learning',    
                      2000         
                    ]}
                    className="type"
                    cursor={true}
                    wrapper="a"
                    repeat={Infinity}/></span></p>
               {/* caffeine and cats</p> */}
              <img src={me} alt="Cara" draggable="false"/>
            </div>
            <div className="portfolio__header-content-inner">
              <h1>Hello!</h1>
              <p>I work hard because they refuse to pay rent</p>

              {imageChange
                ? <img src={cats3} alt="Cats" draggable="false" onMouseLeave={() => setImageChange(false)}/>
                : <img src={cats2} alt="Cats" draggable="false" onMouseEnter={() => setImageChange(true)}/>  
              }

            </div>
        </div>
    </div>
  )
}

export default Home