import React from 'react'
import './skills.css';
import { DiJava, DiPython, DiHtml5, DiCss3, DiReact, DiMsqlServer, DiBootstrap, DiVisualstudio} from 'react-icons/di';
import { SiJavascript, SiAdobephotoshop } from 'react-icons/si';
import { FaGit } from 'react-icons/fa';

const Skills = () => {
  return (
    <div className="portfolio__skills"  id="skills">
       {/* <h1>My Skills</h1> */}
        <div className="portfolio__skills-content section__padding">
            <div className="portfolio__skills-content-inner">
              <FaGit size="6em" color="white"/>
            </div>
            <div className="portfolio__skills-content-inner">
              <DiReact size="6em" color="white"/>
            </div>
            <div className="portfolio__skills-content-inner">
              <SiJavascript size="6em" color="white"/>
            </div>
            <div className="portfolio__skills-content-inner">
              <DiJava size="6em" color="white"/>
            </div>
            <div className="portfolio__skills-content-inner">
              <DiPython size="6em" color="white"/>
            </div>
            <div className="portfolio__skills-content-inner">
              <DiMsqlServer size="6em" color="white"/>
            </div>
            <div className="portfolio__skills-content-inner">
              <DiBootstrap size="6em" color="white"/>
            </div>
            <div className="portfolio__skills-content-inner">
              <DiHtml5 size="6em" color="white"/>
            </div>
            <div className="portfolio__skills-content-inner">
              <DiCss3 size="6em" color="white"/>
            </div>

            <div className="portfolio__skills-content-inner">
              {/* ADD GITHUB 
              ADD VSCODE
              ADD web access? */}
              <DiVisualstudio size="6em" color="white"/>
            </div>
            <div className="portfolio__skills-content-inner">
              <SiAdobephotoshop size="5em" color="white"/>
            </div>
            <div className="portfolio__skills-content-inner">
              {/* <DiResponsive size="7em" color="white"/> */}
            </div>
        </div>
    </div>
  )
}

export default Skills