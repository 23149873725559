import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProjectViews } from './components';

import './index.css';
import App from './App';


ReactDOM.render(<BrowserRouter>
                    <Routes>
                        <Route exact path="/" element={< App />} />
                        <Route exact path="/shelterAPI" element={<div className="pattern"><div style={{backgroundColor: 'rgba(29,157,203,0.94)'}}>< ProjectViews /></div></div>} />
                        <Route exact path="/pyScraper" element={<div className="pattern"><div style={{backgroundColor: 'rgba(29,157,203,0.94)'}}>< ProjectViews /></div></div>} />
                        <Route exact path="/toxicplants" element={<div className="pattern"><div style={{backgroundColor: 'rgba(29,157,203,0.94)'}}>< ProjectViews /></div></div>} />
                        <Route exact path="/sorting" element={<div className="pattern"><div style={{backgroundColor: 'rgba(29,157,203,0.94)'}}>< ProjectViews /></div></div>} />
                        <Route exact path="/carrental" element={<div className="pattern"><div style={{backgroundColor: 'rgba(29,157,203,0.94)'}}>< ProjectViews /></div></div>} />
                    </Routes>
                </BrowserRouter>,
    document.getElementById('root')
  );