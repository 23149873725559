import React from 'react'
import Views from './Views'
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import './projectViews.css';

const ProjectViews = () => {
  
  return (
    <div>
        <div className="portfolio__projectsview section__margin">
            
            <div className="portfolio__projectsview-content section__padding">
                <div className="portfolio__projectsview-content-inner">
                    <HashLink to='/#projects' className='viewLink'>
                        <a className='view_link2'>
                            <FontAwesomeIcon icon={ faAnglesLeft } size="xs" /> Back
                        </a>
                    </HashLink>
                    < Views />
                </div>
            </div>
        </div>
        {/* <div className="footer__bg">
            < Footer />
        </div> */}
    </div>
  )
}

export default ProjectViews