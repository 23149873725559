import React from 'react'
import { CodeBlock, dracula } from 'react-code-blocks';
import resultRental from '../../../assets/projects/rental/resultRental.PNG';

const CarRental = () => {
  return (
    <div>
        <div className='viewscontent-block'>
            <div className='text-container'>
                <h2>The Goal</h2><br/>
                <p>The motivation of this project was to learn how to use a <span className='tech-words'>JSON</span> data file and to display the relevant information to the front-end. 
                <br/><br/> The main objectives were to display a list of all users, allow a user to select their name, display user specific information, <br/> and lastly, to return a total cost of renting a car for that specific user.
                </p>
            </div>
        </div>
        <hr/>
        <div className='viewscontent-block'>
            <div className='text-container'>
                <h2>The Plan</h2> <br/>
                <p>My first step was to create a simple interface for a user to search their last name as well as a simple form for a rental car with appropriate add-ons.
                    Once complete, I created a <span className='tech-words'>Javascript function</span> to parse the data from the <span className='tech-words'>JSON</span> file.<br/><br/> 
                    Using <span className='tech-words'>XMLHttpRequest</span> I made a GET call to a 'rentalclients.json' file. This file holds data about the user such as their first name, last name, phone number, address and email. 
                    The user can then select their profile and their data is displayed in the simple rental car form where they can order their desired rental car.
                </p>
            </div>
            <div className='code-container'>
                < CodeBlock
                    text = {
`xhr.onreadystatechange = function() 
{
    if (xhr.readyState == 4 && xhr.status == 200) 
    {
        r = JSON.parse(xhr.responseText);
    }
};
xhr.open("GET", "rentalclients.json", true);
xhr.send();
`}
                    theme = {dracula}
                    codeBlock
                    wrapLines
                    showLineNumbers = {false}
                    language= "javascript"
                />
            </div>
        </div>
        <hr/>
        <div className='viewscontent-block'>
            <div className='text-container'>
                <h2>The Result</h2><br/>
                <p>The functions worked successfully!<br/><br/>When a user selects their profile they are prompted to complete a rental car and add-ons form.
                    I wanted the form to also calculate a total cost for the user and display a success message.<br/><br/>
                    This project was invaluable as it allowed me to learn how to manipulate the contents of a <span className='tech-words'>JSON object</span>.  
                </p>
            </div>
            <div className='img-container'>
                <img src={resultRental} alt="projectPicture" draggable="false"/>
            </div>
        </div>
    </div>
  )
}

export default CarRental