import React from 'react'
import { CodeBlock, dracula } from 'react-code-blocks';
import ReturnedData from '../../../assets/projects/api/ReturnedData2.png';

const API = () => {
  return (
            <div>
                <div className='viewscontent-block'>
                    <div className='text-container'>
                        <h2>The Goal</h2><br/>
                        <p>
                            It's true that I have a passion for data and coding but I also have a passion for seeing all the cute dogs Calgary has to offer!
                            This API was a great project to learn more about <span className='tech-words'>Node.js</span> but also to build something that could be used to centralize 
                            all dogs that are seeking homes in the Calgary area.
                            To start, I initilized 3 packages in this project, Axios, Cheerio and Express. <br/> <br/>
                            <div className='points-container'>
                                <ul className='points'>
                                    <li><b><span className='tech-words'>Axios</span></b> Performing HttpRequests from node.js</li>
                                    <li><b><span className='tech-words'>Cheerio</span></b> Parses through markup language and provides an API for traversing</li>
                                    <li><b><span className='tech-words'>Express</span></b> Routing used to handle GET requests</li>
                                </ul>
                            </div>                            
                        </p>
                    </div>
                    <div className='code-container'>
                        < CodeBlock
                            text = {
                        `   //initialize packages
    const express = require('express')   
    const axios = require('axios')   
    const cheerio = require('cheerio')   
     
    //call express
    const app = express()`}
                            theme = {dracula}
                            codeBlock
                            wrapLines
                            showLineNumbers = {false}
                            language= "JavaScript"
                        />
                    </div>
                </div>
                <hr/>
                <div className='viewscontent-block'>
                    <div className='text-container'>
                        <h2>The Plan</h2> <br/>
                        <p>An array of shelter objects was created and holds both a shelter name and a URL address. 
                            The program loops through each shelter and makes an axios <span className='tech-words'>get request</span> using the shelters address property. 
                            The <span className='tech-words'>.then</span> method made it possible for the response data to be loaded by Cheerio,
                            which allowed for easy parsing using JQuery.
                        </p>
                    </div>
                    <div className='code-container'>
                        < CodeBlock
                            text = {
                        `shelters.forEach(shelter => {
        axios.get(shelter.address)
        .then((response) => {
            const html = response.data
            const $ = cheerio.load(html)
            
            if(shelter.name == 'calgaryhumane'){
                //etc...
            }
        })`}
                            theme = {dracula}
                            codeBlock
                            wrapLines
                            showLineNumbers = {false}
                            language= "JavaScript"
                        />
                    </div>
                </div>
                <hr/>
                <div className='viewscontent-block'>
                    <div className='text-container'>
                        <h2>The Result</h2><br/>
                        <p>Currently there are 5 <span className='tech-words'>endpoints</span> created for this API. The image seen here shows the /dogs endpoint. 
                        The API was tested using <span className='tech-words'>Postman</span> and returned with a <span className='tech-words'>200 status response code</span> indicating the requests were successful. 
                        <br/><br/>Future plans for this API include expanding it to include all Calgary and Edmonton shelters and connecting to <span className='tech-words'>RapidAPI</span> for anyone interested in this information.
                        <br/><br/>For now, you can find it hosted <a href="https://abshelterapi.onrender.com" className='projectViews-link' target= "_blank">here</a> on Render.<br/><br/>
                            <div className='points-container'>
                                <ul className='points'>
                                    <li><b>/</b><li>A simple welcome message and instructions</li></li>
                                    <li><b>/dogs</b><li>All dogs gathered across the shelters are displayed</li></li>
                                    <li><b>/aarcs</b><li>Only AARCS dogs are displayed</li></li>
                                    <li><b>/pawsitive</b><li>Only Pawsitive Match dogs are displayed</li></li>
                                    <li><b>/calgaryhumane</b><li>Only Calgary Humane Society dogs are displayed</li></li>
                                </ul>
                            </div>
                        </p>
                    </div>
                    <div className='img-container'>
                        <img src={ReturnedData} alt="projectPicture" draggable="false"/>
                    </div>
                </div>
            </div>
  )
}

export default API