import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import API from './views/API';
import PyScraper from './views/PyScraper';
import ToxicPlants from './views/ToxicPlants';
import AlgoSort from './views/AlgoSort';
import CarRental from './views/CarRental';


const Views = () => {
    const [title, setTitle] = useState("")
    const currentURL = useLocation();

    useEffect(() => {
        let ignore = false;
        
        if (!ignore)  projectChange() 
        return () => { ignore = true; }
    },[]);


    function projectChange(){
        if(currentURL.pathname === '/shelterAPI'){
            setTitle("Calgary Shelter API")
        
        }else if(currentURL.pathname === '/pyScraper'){
            setTitle("Plant Web Scraper")

        }else if(currentURL.pathname === '/toxicplants'){
            setTitle("Toxic Plants Web Page")

        }else if(currentURL.pathname === '/sorting'){
            setTitle("Sorting Algorithms")
        }else if(currentURL.pathname === '/carrental'){
            setTitle("Car Rental - Dodgy Brakes")
        }else{

        }
    }

  return (
    <div>
        {title === "Calgary Shelter API" ? 
            <div className='viewscontent-block_container'>
                <h1>{title}</h1>
                < API />
            </div>
            : null }
        {title === "Plant Web Scraper" ? 
            <div className='viewscontent-block_container'>
                <h1>{title}</h1>
                < PyScraper />
            </div>  
            : null }
        {title === "Toxic Plants Web Page" ? 
            <div className='viewscontent-block_container'>
                <h1>{title}</h1>
                < ToxicPlants />
            </div> 
            : null }
        {title === "Sorting Algorithms" ?
            <div className='viewscontent-block_container'>
                <h1>{title}</h1>
                < AlgoSort />
            </div> 
            : null }
        {title === "Car Rental - Dodgy Brakes" ?
            <div className='viewscontent-block_container'>
                <h1>{title}</h1>
                < CarRental />
            </div> 
            : null }
        {title === "" ? 
            <h1>{title}</h1>
            // < PyScraper />  
            : null }
    </div>
    
  )
}

export default Views