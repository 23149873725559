import React, { useState } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const FORM_ENDPOINT = "https://formsubmit.co/ajax/b7015bd82689eccf47cc7c57f325f985";

const ContactForm = () => {
    const [submitted, setSubmitted] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
  
    const handleSubmit = async (event) => {
        event.preventDefault();

        //POST Message
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.post(FORM_ENDPOINT, {
            name: name,
            email: email,
            message: message,
        })
            .then(response => console.log(response))
            .catch(error => console.log(error));

        setTimeout(() => {
            setSubmitted(true);
            setName("");
            setEmail("");
            setMessage("");
        }, 100);
    };

    if (submitted) {
        return (
              
          <div className="formMessage">
              <h2>Thank you for your message! <FontAwesomeIcon icon={faPaperPlane} /></h2>
          </div>
        );
  }

  return (
    <form onSubmit={ handleSubmit }>
      <div>
        <label for="name">Name</label>
        <input type="text" id="name" placeholder="Name" name="name" value={name} onChange={e => setName(e.target.value)} required />
      </div>
      <div>
        <label for="email">Email</label>
        <input type="email" id="email" placeholder="Email" name="email" value={email} onChange={e => setEmail(e.target.value)} required />
      </div>
      <div>
        <label for="message">Message</label>
        <textarea placeholder="Write your message here..."  id="message" name="message" value={message} onChange={e => setMessage(e.target.value)} required />
      </div>
      <div>
        <input type="hidden" name="_template" value="table"/>
        <input type="hidden" name="_captcha" value="false"/>
        <button type="submit"> Send Message &nbsp; <FontAwesomeIcon icon={faPaperPlane} /> </button>
      </div>
    </form>
  );
};

export default ContactForm;