import React, { useState } from 'react';
import './about.css';


const About = () => {
  
    const [radiolength, setLength] = useState("longest");
    const [paragraph, setParagraph] = useState("My name is Cara McGinley, I attended Southern Alberta's Institute of Technology and am a graduate of their software development program." +
    "I love to create applications, explore datasets and design fun and appealing web pages." 
    + "I enjoy building solutions and diving into the root of any problems or questions brought my way.\n\n" 
    + "As a passionate and creative developer I strive to bring a positive impact into the world no matter how small. Outside of my work, you can find me" 
    + " playing some video games, enjoying a good book or"
    + " hanging out with my cats and foster dogs in the beautiful city of Calgary, AB. ")

    function onChangeValue(event) {
      setLength(event.target.value);      
      onChangeParagraph(event.target.value)
    }

    function onChangeParagraph(x){
      console.log(x)
      console.log(radiolength)
      if(x === "short") {
        setParagraph("I'm Cara! I love code, coffee, cats and data!")
      } else if (x === "med"){
        setParagraph("I'm Cara McGinley, I am a graduate of the software development major.\nI love building with code and data! ")
      }else if (x === "long"){
        setParagraph("My name is Cara McGinley, I attended Southern Alberta's Institute of Technology and am a graduate of their software development program. " +
       "I love to build applications, explore datasets and design fun frontends. I have a passion for making a positive impact in the world no matter how small.")
      }else{
        setParagraph("My name is Cara McGinley, I attended Southern Alberta's Institute of Technology and am a graduate of their software development program." +
        "I love to create applications, explore datasets and design fun and appealing web pages." 
        + "I enjoy building solutions and diving into the root of any problems or questions brought my way.\n\n" 
        + "As a passionate and creative developer I strive to bring a positive impact into the world no matter how small. Outside of my work, you can find me" 
        + " playing some video games, enjoying a good book or"
        + " hanging out with my cats and foster dogs in the beautiful city of Calgary, AB. ")
      }
    }

  return (
    <div className="portfolio__about "  id="about">
        <div className="portfolio__about-content">
          <div className="portfolio__about-content-container section__padding" onChange={onChangeValue}>
          <h1>Who I Am</h1>
            <p id="cap">Too long didn't read? Adjust below!</p><br/>
            <div className="content-container-buttons">
           
              <div>
                <input className="about-input" type="radio" name="inputlength" id="short" value="short" />
              </div>
              {/* <div>
                <input className="about-input" type="radio" name="inputlength" value="med" /> 
              </div> */}
              <div>
                <input className="about-input" type="radio" name="inputlength" value="long" /> 
              </div>
              <div>
                <input className="about-input" type="radio" name="inputlength" id="longest" value="longest" defaultChecked/>
              </div>
            </div>
            <div className="content-bar-labels">
              <label htmlFor="short" className="lengthLbl">Short!</label>
              <label htmlFor="long" className="lengthLbl">Medium</label>
              <label htmlFor="longest" className="lengthLbl">Long!</label>
            </div>
            <div className="portfolio__about-content-inner">
              <br/><br/>
              <p>{paragraph}</p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default About