import React from 'react';
import { Slide } from 'react-slideshow-image';
import { Link } from "react-router-dom";
import 'react-slideshow-image/dist/styles.css';
import './projectSlide.css';
import dogBg from '../../assets/projects/api/dogunsplash3.jpg';
import pyBg from '../../assets/projects/pyscraper/img_emile-perron2.jpg';
import plantBg from '../../assets/projects/toxicplants/ian-chen.jpg';
import sortBg from '../../assets/projects/sorting/sorting4.jpg';
import carBg from '../../assets/projects/rental/dodgeBrakes.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesLeft, faAnglesRight,  } from '@fortawesome/free-solid-svg-icons';


const projects = [
  {
    url:  `url(${dogBg})`,
    color: 'black',
    imagesize: 'cover',
    projectName: 'Shelter API',
    desc: 'This API scrapes adoptable dog data from shelters in and around the Calgary area. GET calls return a JSON object that holds a URL, Name, Picture and its placement in the list (labeled as ID) of the dog object.',
    github: 'https://github.com/caraMcG/CalgaryShelterAPI',
    view: '/shelterAPI',
    Bgcolor: 'rgb(36,156,200)'
  },
  {
    url: `url(${pyBg})`,
    color: 'black',
    imagesize: 'cover',
    projectName: 'Python Scraper',
    desc: 'I began this project in order to learn more Python but to also generate my own MS SQL Server database. I used BeautifulSoup4, Pandas and Requests libraries. The generated database was later utilized when connecting to a JSP in my Toxic Plants project (on the next slide!).',
    github: 'https://github.com/caraMcG/PlantsScraper',
    view: '/pyScraper',
    Bgcolor: '#f9f9f9'
  },
  {
    url: `url(${plantBg})`,
    color: 'black',
    imagesize: 'cover',
    projectName: 'Toxic Plants',
    desc: 'Web application created with Java/JSP and CSS. The user can select either a plant name or symptom and proceed to type their entry into the search bar. The query is executed against the database and outputs any matching results. Additionally, a Python web scraper was built to populate the database.',
    github: 'https://github.com/caraMcG/Plants-Pets',
    view: '/toxicplants',
    Bgcolor: 'rgb(36,156,200)'
  },
  {
    url: `url(${sortBg})`,
    color: 'black',
    imagesize: 'cover',
    projectName: 'Sorting Algorithms',
    desc: 'A Java program that uses Bubble, Selection, Insertion and Merge sorts. This was implemented using an ADT that represents a polygon and uses a compareTo method. The user enters a command line argument, the program then reads the text file to sort and print the first and every thousandth value of an array.',
    github: 'https://github.com/caraMcG/SortingAlgorithms',
    view: '/sorting',
    Bgcolor: '#f9f9f9'
  },
  {
    url: `url(${carBg})`,
    color: 'black',
    imagesize: 'cover',
    projectName: 'Car Rental',
    desc:'Front-end website created in JavaScript, HTML and CSS for a car rental service. The user can type in the search field which will narrow down a list as the user types their name. The client data is stored in a JSON file. The user can then fill in a rental form and submit their request.',
    github: 'https://github.com/caraMcG/CarRental',
    view: '/carrental',
    Bgcolor: 'rgb(36,156,200)'
  },
  
];
const properties = {
    transitionDuration: 1250,
    infinite: true,
    easing: "ease",
    indicators: true,
    nextArrow: <button><FontAwesomeIcon icon={ faAnglesRight } size="2xl" /></button>,
    prevArrow: <button><FontAwesomeIcon icon={ faAnglesLeft } size="2xl" /></button>,

}
const ProjectSlide = () => {
  return (
    <div className="portfolio__projects"  id="projects">
      {/* <div>
        <h1>Things I've Built</h1>
      </div> */}
      <div className="slide-container">
        <Slide {...properties}>
          {projects.map((project, index)=> (
            <div className="each-slide" key={index}>
              <div style={{ 
                            display: 'flex',
                            color: `${project.color}`,
                            backgroundImage: `${project.url}`,
                            backgroundSize: `${project.imagesize}`,
                            height: '100vh',
                            backgroundColor: `${project.Bgcolor}`,
                           
                          } }>
                  <div className='portfolio__projectSlide-box'>
                    <div className='portfolio__projectSlide-box-inner'>
                      <h2 className='portfolio__projectSlide-projectName'>{project.projectName}</h2>
                      <br/>
                      <span>{project.desc}</span>
                      <br/> <br/>
                      <div className="portfolio__projectSlide-links">
                        
                          <a href={project.github} target="_blank" className='view_link'>
                            GitHub
                          </a>
                          
                          <Link to={project.view} className='view_link'>View</Link>
                      </div>
                    </div>
                    <div className='portfolio__projectSlide-box-inner'>
                    </div>

                  </div>
              </div>
            </div>
          ))}
        </Slide>
          
      </div>
    </div>
  )
}

export default ProjectSlide