import React, { useEffect } from 'react'
import './contact.css';
import ContactForm from './ContactForm';

const Contact = () => {

  return (
  <div className="portfolio__contact section__margin" id="contact">
    <div className="portfolio__contact-content section-padding">
      <div className="portfolio__contact_text">
        <h1>Get in Touch!</h1>
        <br/>
        
        <p style={{ textAlign: "justify"}}>Whether you have a question, opportunity, or just want to say hi, my inbox is open.</p>

      </div>
      <div className="portfolio__contact_form">
       
           < ContactForm />
          
      </div>
    </div>
  </div>
  )
}

export default Contact