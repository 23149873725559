import React from 'react'
import plant3 from '../../../assets/projects/toxicplants/plant_3_1.png';

const ToxicPlants = () => {
  return (
    <div>
        <div className='viewscontent-block'>
            <div className='text-container'>
                <h2>The Goal</h2><br/>
                <p>This project was motivated by the Python web scraper project I have also listed on my projects page. I had a fair amount of data collected about toxic plants and 
                    I wanted a way to display them on a web page in case I ever wanted to create a live service! I found this was also a fantastic refresher on using <span className='tech-words'>Java Servlets</span>. 
                    This program utilizes <span className='tech-words'>JDBC</span> to create a database connection and <span className='tech-words'>JSP</span> and CSS for the front-end of the website. 
                    <br/><br/>All data credit goes to <a href="https://www.rover.com/blog/poisonous-plants/" className='projectViews-link' target= "_blank">Rover</a>.<br/>
                </p>
            </div>
        </div>
        <hr/>
        <div className='viewscontent-block'>
            <div className='text-container'>
                <h2>The Plan</h2> <br/>
                <p>An index.jsp file is built to create the front-end; A simple form that utilizes a <span className='tech-words'>GET method</span>  and 'SearchServlet' action is used.
                    Once a search has been typed and submitted by the user, the program runs <span className='tech-words'>searchServlet.java</span> which, based upon the submitted search, sends a call to a <span className='tech-words'>plantDB.java method.</span>
                    This class then sends <span className='tech-words'>prepared statements</span> to the database and returns a table to the main index.js. 
                </p>
            </div>
        </div>
        <hr/>
        <div className='viewscontent-block'>
            <div className='text-container'>
                <h2>The Result</h2><br/>
                <p>
                    The program successfully runs and returns a table set to the main page. A method in the program highlights the specific search term so the user can visually see what was matched in the returned data. 
                    The user can then choose to reset the search. In the future I would like to add more information to the database and create a mobile friendly version of this using <span className='tech-words'>Vue.js</span> or <span className='tech-words'>React</span>.
                </p>
            </div>
            <div className='img-container'>
                <img src={plant3} alt="projectPicture" draggable="false"/>
            </div>
        </div>
    </div>
  )
}

export default ToxicPlants