import React from 'react'
import './footer.css';
import { FaLinkedinIn, FaGithub } from 'react-icons/fa';

const Footer = () => {
  return (
    
    <div className="portfolio__footer section__margin">
      <div className="portfolio__footer-content section__padding">
        <div className="portfolio__footer-content-text">
          <hr id="hr"/>
            <h3><a href="#about" >About</a></h3>
            <h3><a href="#projects">Projects</a></h3>
            <h3><a href="#skills" >Skills</a></h3>
            <h3><a href="#contact" >Contact</a></h3>
          <hr id="hr"/>
        </div>
        <div className="portfolio__footer-content-icons">
          <a href="https://www.linkedin.com/in/cara-mcginley/" target="_blank">
            <FaLinkedinIn size="2em" color="white" className="FIcon"/>
					</a>
          <a href="https://github.com/caraMcG" target="_blank">
            <FaGithub size="2em" color="white"/>
          </a>
        </div>
        <br/><br/>
        <div className="portfolio__footer-content-text">
          <span>Images from Unsplash ♥ - 
            <a href="https://unsplash.com/photos/inI8GnmS190"> 1, </a> 
            <a href="https://unsplash.com/photos/3zMIdTPAs8g">2, </a>
            <a href="https://unsplash.com/photos/N04FIfHhv_k">3, </a>
            <a href="https://unsplash.com/photos/xrVDYZRGdw4">4 </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Footer